eb-filter {
    display: block;
    margin-bottom: 0.5em;
    //padding-left: 0.5em;
    border-radius: 1em;
    padding-left: 0.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    &.lg-border{
        border-left: 5px solid lightgreen;

        & > eb-filter-foot{
            & > :nth-child(2){
                background: lightgreen;
            }
        }
    }

    &.lb-border{
        border-left: 5px solid lightblue;

        & > eb-filter-foot{
            & > :nth-child(2){
                background: lightblue;
            }
        }
    }

    &.lo-border{
        border-left: 5px solid orange;

        & > eb-filter-foot{
            & > :nth-child(2){
                background: orange;
            }
        }
    }

    & > eb-filter-head {
        display: grid;
        grid-template-columns: 1fr min-content;
    }

    & > eb-filter-list {
        display: block;
        padding-left: 1em;
        border-left: 1px solid var(--eb-gray-600);
        margin-top: 0.5em;

        & > eb-filter-list-item {
            display: grid;
            grid-template-columns: 1fr 20% 2fr max-content;
            padding-bottom: 0.5em;

        }
    }

    & > eb-filter-foot {
        display: flex;
        justify-content: end;
    }
}