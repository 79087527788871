div.table {
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
    gap: 1px;
    //padding: var(--spacing-xl) 0;
    overflow: auto;
    height: fit-content;
    
    &.table--fill {
        height: 100%;
        height: -moz-available;          /* WebKit-based browsers will ignore this. */
        height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    }

    & > div.table__controls {
        display: flex;
        justify-content: end;
        gap: 0.5rem;
        padding: var(--spacing-lg);
        
        & > :first-child {
            flex: 1 0 auto;
        }

        &:not(:has(:not([style*="display: none"]))), /* Fix for hidden objects */
        &:not(:has(:not(.hidden))) /* Fix for hidden objects */,
        &:empty {
            display: none;
        }
    }

    div.table__content {
        display: grid;
        overflow-x: auto;
        overflow-y: clip;
    }

    div.table__header {
        display: flex;
        justify-content: start;
        font-weight: 600;
        gap: var(--spacing-md);
        overflow-x: clip;
        border-bottom: var(--border-width) solid var(--border-color);
        padding: var(--spacing-xs);

        font-size: var(--table-header-text);
        color: var(--table-header-color);
        fill: var(--table-header-color);    
        background: var(--table-header-background); 
        border-top-left-radius: 8px; /* Rounds the top-left corner */
        border-top-right-radius: 8px; /* Rounds the top-right corner */    

        & > div {
            display: inline-flex;
            gap: var(--spacing-xs);
            padding: var(--spacing-lg);
            text-transform: uppercase;

            &:last-of-type {
                width: 2rem;
            }

            & > svg {
                fill: var(--table-header-svg);
                color: var(--table-header-svg);
            }

            &[aria-sort="ascending"] > svg > path.toggle--down {
                color: var(--color-blue);
                fill: var(--color-blue);
            }

            &[aria-sort="descending"] > svg > path.toggle--up {
                color: var(--color-blue);
                fill: var(--color-blue);
            }
        }
    }

    div.table__rows {
        display: grid;
        overflow-y: auto;
        overflow-x: clip;

        background: var(--table-rows-background);  
        font-size: var(--font-height-md);

        a {
            color: var(--nav-link-color);
            fill: var(--nav-link-color);            
        }

        & > div {
            &:nth-of-type(even) {
                background: var(--table-rows-background-alt);
            }
        }

        & > div.table__pager {
            display: grid;
            grid-template-columns: 1fr max-content 1fr;
            column-gap: 1em;
            justify-content: center;
            padding: 1rem;

            & > div:nth-child(2) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 1em;
            }
        }
    }

	div.table__insert {
		background: var(--table-rows-insert-background);
		select {
			color: var(--table-rows-insert-color);
		}
	}

    div.table__rows > div[role="row"],
    div.table__insert {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: var(--spacing-md);
        padding: var(--spacing-xs);
        

        & > div[role="cell"] {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: var(--spacing-lg);

            &:last-of-type {
                width: 2rem;
                & > button {
                    background: none;
                    border: none;
                    padding: var(--spacing-md);
                    box-shadow: none;
                }
            }
        }
    }
}