div.dragSort {  
    &.table--fill {
    }

    & > div.table__controls {     
        & > :first-child {
        }

        &:not(:has(:not([style*="display: none"]))), /* Fix for hidden objects */
        &:not(:has(:not(.hidden))) /* Fix for hidden objects */,
        &:empty {
        }
    }

    div.table__content {
    }

    div.table__header { 

        & > div {

            &:last-of-type {
            }

            & > svg {
            }

            &[aria-sort="ascending"] > svg > path.toggle--down {
            }

            &[aria-sort="descending"] > svg > path.toggle--up {
            }
        }
    }

    div.table__rows {

        a {        
        }

        & > div {
            &:nth-of-type(even) {
            }
            & > .orderAbove {
                display: none;
            }
            & > .orderBelow {
                display: none;
            }
        }
    }

	div.table__insert {
		select {
		}
	}
    
    div.table__rows.sorting > div {
        &[draggable=true]{
            & > .orderAbove {
                display: block;
            }
            & > .orderBelow {
                display: block;
            }
        }
    }

    div.table__rows > div[role="row"]::before,
    div.table__header[role="row"]::before {
        content: "";
        width: 0.2rem;
    }

    div.table__rows > div[role="row"][draggable="true"]::before {
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" fill="%23000" /></svg>');
    }

    div.table__rows > div[role="row"],
    div.table__insert {


        &[draggable="true"]{
            cursor: move;
        }
        
        &.dragging {
            background: orange;
        }

        & > .orderAbove {
            position: absolute;
            z-index: 12;
            top: 0;
            left: 0;
            width: 100%;
            height: 50%;
        }
        & > .orderBelow {
            position: absolute;
            z-index: 12;
            top: 50%;
            left: 0;
            width: 100%;
            height: 50%;
        }

        & > div[role="cell"] {

            &:last-of-type {
                & > button {
                }
            }
        }
    }
}

body[data-theme="dark"] {
    div.dragSort {
        div.table__rows > div[role="row"][draggable="true"]::before {
            content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" fill="%23fff" /></svg>')!important;
        }
    }
}